.popup__form {
  display: flex;
  flex-direction: column;
  margin: 0 36px 37px;
  padding: 0;
  /*border: 1px solid red;*/
}

@media screen and (max-width: 550px) {
  .popup__form {
    margin: 0 22px 25px;
  }
}