.profile__button-add {
  width: 150px;
  height: 50px;
  margin-left: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #000000;
  background-image: url(../../../images/plus_add-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
  transition: 0.5s;
}

.profile__button-add:hover {
  opacity: 50%;
  cursor: pointer;
}

@media screen and (max-width: 899px) {
  .profile__button-add {
    width: 70%;
    margin-left: 0;
  }
}

@media screen and (max-width: 450px) {
  .profile__button-add {
    width: 100%;
    background-size: 16px;
  }
}