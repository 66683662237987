.popup__close {
  position: absolute;
  display: flex;
  width: 32px;
  height: 32px;
  margin: 0;
  top: -40px;
  right: -40px;
  padding: 0;
  border: none;
  background: none;
  transition: 0.3s;
  /*border: 1px solid red;*/
}

.popup__close:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 618px) {
  .popup__close {
    width: 20px;
    height: 20px;
    top: -36px;
    right: 0;
  }
}