.menu__link-entry {
  margin: 0 0 40px;
  text-decoration: none;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #A9A9A9;
  transition: 0.8s;
}

.menu__link-entry:hover {
  opacity: 0.5;
  cursor: pointer;
}