.header__close-menu {
  width: 20px;
  height: 20px;
  display: none;
  margin: 5px 30px 0 0;
  padding: 0;
}

.header__close-menu:hover {
  opacity: 0.5;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .header__close-menu {
    display: block;
  }
}