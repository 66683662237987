.footer {
  max-width: 880px;
  display: flex;
  padding: 0;
  margin: 67px auto 0;
  transition: 0.8s;
  /*border: 1px solid red;*/
}

@media screen and (max-width: 920px) {
  .footer {
    margin: 48px 19px 0;
  }
}