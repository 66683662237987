.popup__save-button_delete-confirm {
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 37px;
}

@media screen and (max-width: 618px) {
  .popup__save-button_delete-confirm {
    margin: 15px 22px 25px;
  }
}