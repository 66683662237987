.popup__container {
  position: relative;
  top: -75px;
  width: 430px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  color: #000000;
}

@media screen and (max-width: 618px) {
  .popup__container {
    width: 282px;
    top: 0;
  }
}