.profile {
  max-width: 880px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: auto;
  gap: 34px 0;
  margin-top: 40px;
  /*border: 1px solid red;*/
}

@media screen and (max-width: 899px) {
  .profile {
    justify-content: center;
    margin: 42px 19px 0;
  }
}