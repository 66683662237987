.popup__title {
  width: 330px;
  margin: 34px 64px 47px 36px;
  padding: 0;
  text-align: start;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: black;
  /*border: 1px solid red;*/
}

@media screen and (max-width: 618px) {
  .popup__title {
    width: 238px;
    margin: 25px 22px 30px;
    font-size: 18px;
    line-height: 22px;
  }
}