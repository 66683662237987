.popup__message {
  max-width: 358px;
  margin: 0 auto 60px;
  padding: 0;
  text-align: center;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

@media screen and (max-width: 618px) {
  .popup__message {
    width: 244px;
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 24px;
  }
}