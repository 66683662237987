.profile__info {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  margin-left: 3.5px;
  padding: 0;
  gap: 7px;
  /*border: 1px solid rgb(255, 0, 0);*/
}

@media screen and (max-width: 735px) {
  .profile__info {
    min-width: 100%;
    align-items: center;
    margin-left: 0;
  }
}