.popup__image-symbol {
  max-width: 120px;
  padding: 0;
  margin: 60px auto 32px;
}

@media screen and (max-width: 618px) {
  .popup__image-symbol {
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 24px;
    margin-top: 50px;
    margin-bottom: 40px;
  }
}