.profile__button-edit {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  background-color: #000000;
  background-image: url(../../../images/pen_edit-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  transition: 0.5s;
}

.profile__button-edit:hover {
  opacity: 60%;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  .profile__button-edit {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
    background-size: 7.5px;
  }
}