.elements__rectangle {
  min-height: 362px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  /*border: 1px solid red;*/
}