.profile__ellipse {
  width: 120px;
  height: 120px;
  margin: 0;
  margin-right: 27.2px;
  padding: 0;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  transition: 1s;
  /*border: 1px solid red;*/
}

.profile__ellipse::before {
  content: '';
  position: absolute;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: no-repeat rgba(0, 0, 0, 0.8) url(../../../images/pen_edit_avatar.svg);
  background-position: center center;
  background-size: 26px;
  text-decoration: none;
  opacity: 0;
  z-index: 2;
  transition: 1s;
}

.profile__ellipse:hover::before {
  opacity: 1;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  .profile__ellipse {
    margin-right: 0;
  }
}