.popup__input {
  margin: 0;
  padding: 0 0 7px;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #000000;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  /*border: 1px solid red;*/
}

.popup__input::-moz-placeholder {
  color: #C4C4C4;
}

.popup__input::-webkit-input-placeholder {
  color: #C4C4C4;
}
