.popup__input-error {
  min-height: 30px;
  padding: 3px 0 0;
  visibility: hidden;
  opacity: 0;
  /* transition: visibility 0.5s, opacity 0.5s linear; */
  margin: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
}