.elements__mask-group {
  min-width: 100%;
  height: 282px;
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: cover;
  vertical-align: bottom;
  overflow: hidden;
  text-align: center;
  background: no-repeat rgba(216, 212, 212, 0.574) url(../../../images/noimage.png);
  background-position: center center;
  background-size: 150px;
  /*border: 1px solid red;*/
}

.elements__mask-group:hover {
  cursor: pointer;
}