.elements__title {
  max-width: 100%;
  margin: 24px 62px 26px 21px;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #000000;
  /* border: 1px solid red; */
}