.profile__subtitle {
  max-width: 420px;
  margin: 0 0 22px;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #FFFFFF;
  /*border: 1px solid rgb(18, 115, 224);*/
}

@media screen and (max-width: 735px) {
  .profile__subtitle {
    max-width: 100%;
    margin: 0;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
  }
}