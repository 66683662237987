.header__email {
  padding: 5px 0 0;
  margin: 0;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #FFFFFF;
  transition: 0.8s;
  /* border: 1px solid red; */
}

@media screen and (max-width: 670px) {
  .header__email {
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 450px) {
  .header__email {
    display: none;
  }
}