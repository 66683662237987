.elements__group {
  width: 22px;
  height: 19px;
  padding: 0;
  margin: 0;
  background-image: url(../../../images/heart.svg);
  background-color: #FFFFFF;
  background-position: center;
  transition: 0.5s;
  border: none;
  /*border: 1px solid red;*/
}

.elements__group:hover {
  opacity: 50%;
  cursor: pointer;
}