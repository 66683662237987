.profile__figure {
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  /*border: 1px solid rgb(15, 216, 75);*/
}

@media screen and (max-width: 735px) {
  .profile__figure {
    gap: 25px 0;
    margin: auto;
    justify-content: center;
  }
}